<template>
  <div class="eighth container">
    <div class="eighth-inner">
      <section class="eighth-left">
        <h2 class="eighth-left__title wow" data-wow-offset="150">
          Удобства, которые выделит для себя любой житель
        </h2>
        <div class="eighth-left__tabs wow" data-wow-offset="200">
          <Tabs :tabs="tabs" @select="changeCurrentTab" />
        </div>
        <section class="eighth-left-bottom wow" data-wow-offset="150">
          <img
            :src="changeValue.img"
            class="eighth-right__img onlyTabletMin"
            :alt="`картинка удобства - ${changeValue.title} `"
          />
          <h3 class="eighth-left-bottom__title">{{ changeValue.title }}</h3>
          <p class="eighth-left-bottom__text" v-html="changeValue.text"></p>
        </section>
      </section>
      <div class="eighth-right onlyTabletMax wow" data-wow-offset="150">
        <img
          :src="changeValue.img"
          class="eighth-right__img"
          :alt="`картинка удобства - ${changeValue.title} `"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/molecules/Tabs/index.vue'
import { WOW } from 'wowjs'

export default {
  name: 'EighthSection',
  components: { Tabs },
  data() {
    return {
      currentTab: 0,
      tabs: [
        {
          label: 'Семья с детьми',
        },
        {
          label: 'Молодые',
        },
        {
          label: 'Взрослое поколение',
        },
        {
          label: 'Активный отдых',
        },
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
  computed: {
    changeValue() {
      switch (this.currentTab) {
        case 1:
          return {
            img: require(`@/assets/images/eighth/jun.webp`),
            title: 'Молодым',
            text:
              'Рядом с домом имеется все необходимое для прекрасного вечера - театр имени Шанина для желающих провести культурный вечер, а после можно прогуляться до ближайшего ТРЦ, выпить чашечку свежего кофе или прогуляться по бутикам, совершив некую шопинг-терапию',
          }
        case 2:
          return {
            img: require(`@/assets/images/eighth/old.webp`),
            title: 'Комфорт для взрослых',
            text:
              'Разрабатывая концепцию двора, мы не забыли и о взрослом поколении наших жильцов. Во дворе будут расположены беседки для тихих и уютных посиделок, а также прогулочная зона',
          }
        case 3:
          return {
            img: require(`@/assets/images/eighth/active.webp`),
            title: 'Активный отдых',
            text:
              'Для любителей занятий спортом во дворе жилого дома размещены спортивные площадки с различными тренажерами, многофункциональное поле и велопарковка для любителей более активного отдыха',
          }
        default: {
          return {
            img: require(`@/assets/images/eighth/family.webp`),
            title: 'Для семей с детьми',
            text: `Всего в 9 минутах езды на машине от вашего дома находится одна из лучших школ страны - Назарбаев Интеллектуальная школа, если ваш ребенок еще мал для школы, то вы можете посещать любой из ближайших детских садов <br /><br />
            Вы всегда можете спокойно отпускать ребенка гулять во дворе, так как двор будет закрытым и доступ к нему будут иметь лишь жильцы дома. Помимо этого по всему периметру двора будет круглосуточное наблюдение`,
          }
        }
      }
    },
  },
  methods: {
    changeCurrentTab(val) {
      this.currentTab = val
    },
  },
}
</script>

<style lang="scss" scoped>
.eighth {
  width: 100%;
  height: fit-content;

  &-inner {
    gap: 50px;
    justify-content: space-between;
    @include flex-row();

    @include laptop {
      gap: 30px;
      flex-direction: column;
    }

    @include mobile {
      gap: 16px;
    }
  }

  &-left {
    padding-top: 40px;
    align-items: flex-start;
    max-width: 600px;
    @include flex-column();

    @include laptop {
      max-width: 800px;
    }

    @include tablet-portrait {
      padding-top: 0;
      max-width: 80%;
    }
    @include mobile-landscape {
      max-width: none;
      width: 100%;
      order: 2;
    }
    &__title {
      padding-bottom: 30px;
      animation: manifestation1 0.5s ease-in;
      @include font-styles(38px, 48px, 700, $c-dark, 0, $font-third);

      @include mobile {
        padding-bottom: 14px;
        @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
      }
    }

    &__tabs {
      animation: manifestation1 0.5s ease-in;

      @include mobile {
        width: 100vw;
        padding-right: 15px;
      }

      @include mobile-small {
        padding-right: 30px;
      }
      /deep/ button.tab {
        white-space: nowrap;

        @include tablet-portrait {
          white-space: normal;
        }
      }
    }

    &-bottom {
      animation: manifestation1 0.7s ease-in;
      max-width: 600px;
      padding-top: 60px;
      gap: 30px;
      @include flex-column();

      @include laptop {
        max-width: none;
        padding-top: 30px;
      }

      @include mobile {
        gap: 0;
        padding-top: 16px;
      }

      &__title {
        @include font-styles(26px, 36px, 600, $c-dark);

        @include tablet-portrait {
          padding-top: 20px;
        }

        @include mobile {
          padding: 16px 0;
          @include font-styles(18px, 24px, 700, $c-dark);
        }
      }
      &__text {
        @include font-styles(18px, 24px, 400, $c-gray, 0, $font-second);

        @include mobile {
          text-align: left;
          @include font-styles(14px, 20px, 400, $c-gray, 0, $font-second);
        }
      }
    }
  }
  &-right {
    @include flex-row();
    justify-content: center;
    align-items: center;
    animation: manifestation1 0.9s ease-in;

    @include laptop {
      max-width: 650px;
    }
    &__img {
      border-radius: 120px 40px 40px 40px;
      height: 580px;
      min-width: 580px;
      // object-fit: cover;

      @include tablet-portrait {
        object-fit: cover;
        width: 480px;
        height: 480px;
        min-width: auto;
      }
      @include mobile {
        border-radius: 60px 20px 20px 20px;
        width: 280px;
        height: 280px;
      }
    }
  }
}

::v-deep .tab {
  gap: 20px;
  @include tablet-portrait {
    flex-wrap: nowrap;
    overflow-x: scroll;
    gap: 12px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__item {
      min-width: fit-content;
    }
  }
}
</style>
